export default [
  {
    header: 'Records',
  },
  {
    title: 'Register Patient',
    route: 'register-patient',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Patient List',
    route: 'patient-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Referal Ins',
    route: 'patient-referrals',
    icon: 'ExternalLinkIcon',
  },
  {
    title: 'Book Appointment',
    route: 'book-appointment',
    icon: 'EditIcon',
  },
  {
    title: 'Appointment List',
    icon: 'CalendarIcon',
    route: 'appointment-list',
  },
  {
    title: 'Forms Management',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Build Form',
        icon: 'EditIcon',
        route: 'build-form',
      },
      {
        title: 'Forms List',
        icon: 'ExternalLinkIcon',
        route: 'form-list',
      },
      {
        title: 'Edit Form',
        icon: 'Edit2Icon',
        route: 'edit-form',
      },
    ],
  },
]
