export default [
  {
    header: 'Profile',
  },
  {
    title: 'My Accounts',
    route: 'my-accounts',
    icon: 'UserIcon',
  },
  
]
