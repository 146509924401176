export default [
  {
    header: 'MOH REPORTS',
  },
  {
    title: 'MOH 705',
    route: 'moh-705',
    icon: 'FileTextIcon',
  },
  {
    title: 'MOH 706',
    route: 'moh-706',
    icon: 'FileTextIcon',
  },
  {
    title: 'MOH 717',
    route: 'moh-717',
    icon: 'FileTextIcon',
  },
]
