export default [
  {
    header: 'Billing',
  },
  {
    title: 'Patient Accounts',
    route: 'patient-accounts',
    icon: 'UserIcon',
  },
  {
    title: 'Patient Bills',
    route: 'phamarcy',
    icon: 'ListIcon',
  },
]
