export default [
  {
    header: 'Clinical',
  },
  {
    title: 'Patient File',
    route: 'patient-file',
    icon: 'UserIcon',
  },
  {
    title: 'Pharmacy',
    route: 'pharmacy',
    icon: 'EditIcon',
  },
  {
    title: 'Clinical Procedures',
    route: 'procedures',
    icon: 'ListIcon',
  },
]
